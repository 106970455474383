import React, { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import classes from "../Styles/about.module.css";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { BiLinkExternal } from "react-icons/bi";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { contacts, mySocialHandle } from "../Utils/Data";

const Contact = () => {
  const [isProfOpen, setIsProf] = useState(false);

  const [isContactOpen, setIsContact] = useState(true);

  return (
    <>
      <Grid item md={2.4} xs={12} className={classes.grid_item_inner}>
        <Box
          onClick={() => setIsContact(!isContactOpen)}
          sx={{
            display: "flex",
            color: "white",
            width: "100%",
            borderTop: "0.25px solid #1E2D3D",
            borderBottom: "0.25px solid #1E2D3D",
            backgroundColor: { xs: "#1E2D3D", md: "transparent" },
            pt: 2,
            pb: 1,
            mt: { xs: 1, md: 0 },
            mb: { xs: 1, md: 0 },
            cursor: "pointer",
          }}
        >
          {isContactOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

          <Typography className={classes.about_text}>contacts</Typography>
        </Box>
        <Box sx={{ display: isContactOpen ? "block" : "none" }}>
          {contacts?.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                color: "white",
                width: "100%",

                pt: 2,
                pb: 1,
              }}
            >
              {item?.icon}
              <Typography
                className={classes.about_text_folder_under}
                sx={{ wordBreak: "break-word" }}
              >
                {item?.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          onClick={() => setIsProf(!isProfOpen)}
          sx={{
            display: "flex",
            color: "white",
            width: "100%",
            borderTop: "0.25px solid #1E2D3D",
            borderBottom: "0.25px solid #1E2D3D",
            backgroundColor: { xs: "#1E2D3D", md: "transparent" },
            pt: 2,
            pb: 1,
            mt: { xs: 1, md: 0 },
            mb: { xs: 1, md: 0 },
            cursor: "pointer",
          }}
        >
          {isProfOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

          <Typography className={classes.about_text}>
            find-me-also-on
          </Typography>
        </Box>
        <Box sx={{ display: isProfOpen ? "block" : "none" }}>
          <a
            target="blank"
            style={{ textDecoration: "none" }}
            href={mySocialHandle.github_link}
          >
            <Box
              sx={{
                display: "flex",
                color: "white",
                width: "100%",

                pt: 2,
                pb: 1,
              }}
            >
              <BiLinkExternal
                style={{
                  color: "#81A1C1",
                  paddingLeft: "18px",
                  paddingRight: "5px",
                  fontSize: "20px",
                }}
              />
              <Typography
                sx={{
                  "&:hover": {
                    color: "white",
                  },
                }}
                className={classes.about_text_folder_under}
              >
                github-profile
              </Typography>
            </Box>
          </a>

          <a
            target="blank"
            style={{ textDecoration: "none" }}
            href={mySocialHandle.linkedIn_link}
          >
            <Box
              sx={{
                display: "flex",
                color: "white",
                width: "100%",

                pt: 2,
                pb: 1,
              }}
            >
              <BiLinkExternal
                style={{
                  color: "#81A1C1",
                  paddingLeft: "18px",
                  paddingRight: "5px",
                  fontSize: "20px",
                }}
              />
              <Typography
                sx={{
                  "&:hover": {
                    color: "white",
                  },
                }}
                className={classes.about_text_folder_under}
              >
                linkdeln-profile
              </Typography>
            </Box>
          </a>
          <a
            target="blank"
            style={{ textDecoration: "none" }}
            href={mySocialHandle.twitter_link}
          >
            <Box
              sx={{
                display: "flex",
                color: "white",
                width: "100%",

                pt: 2,
                pb: 1,
              }}
            >
              <BiLinkExternal
                style={{
                  color: "#81A1C1",
                  paddingLeft: "18px",
                  paddingRight: "5px",
                  fontSize: "20px",
                }}
              />
              <Typography
                sx={{
                  "&:hover": {
                    color: "white",
                  },
                }}
                className={classes.about_text_folder_under}
              >
                twitter-profile
              </Typography>
            </Box>
          </a>
          <a
            target="blank"
            style={{ textDecoration: "none" }}
            href={mySocialHandle.instagram_link}
          >
            <Box
              sx={{
                display: "flex",
                color: "white",
                width: "100%",
                pt: 2,
                pb: 1,
              }}
            >
              <BiLinkExternal
                style={{
                  color: "#81A1C1",
                  paddingLeft: "18px",
                  paddingRight: "5px",
                  fontSize: "20px",
                }}
              />
              <Typography
                sx={{
                  "&:hover": {
                    color: "white",
                  },
                }}
                className={classes.about_text_folder_under}
              >
                intagram-profile
              </Typography>
            </Box>
          </a>
        </Box>
      </Grid>
    </>
  );
};

export default Contact;
