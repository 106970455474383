import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import classes from "../Styles/navbar.module.css";
import Hamburger from "hamburger-react";
import { NavLink, useLocation } from "react-router-dom";
import "../Styles/text.css";
const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const [scroll, setScroll] = useState(0);
  const [width, setWidth] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScroll(position);
  };
  const handleWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("resize", handleWidth);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  return (
    <>
      <Container
        disableGutters
        maxWidth="xl"
        sx={{ position: "sticky", zIndex: "2" }}
      >
        <Container disableGutters maxWidth="xl">
          <Grid container className={classes.nav_container}>
            <Grid item xs={6} md={3}>
              <NavLink to="/" style={{ textDecoration: "none" }}>
                <Box className={classes.nav_links}>
                  <Typography className={classes.nav_link_name}>
                    Babatunde Adeniyi
                  </Typography>
                </Box>
              </NavLink>
            </Grid>

            <Grid
              item
              xs={1.2}
              md={1.5}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <NavLink to="/" style={{ textDecoration: "none" }}>
                <Box className={classes.nav_links}>
                  <Typography className={classes.nav_link}>
                    &lt;Hello/&gt;
                  </Typography>

                  {location.pathname === "/" ? (
                    <>
                      <Box className={classes.nav_actives} />
                      <Box className={classes.nav_active} />
                    </>
                  ) : (
                    <Box className={classes.nav_active} />
                  )}
                </Box>
              </NavLink>
            </Grid>

            <Grid
              item
              xs={1.2}
              md={1.5}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <NavLink to="about" style={{ textDecoration: "none" }}>
                <Box className={classes.nav_links}>
                  <Typography className={classes.nav_link}>
                    &lt;About/&gt;
                  </Typography>

                  {location.pathname === "/about" ? (
                    <>
                      <Box className={classes.nav_actives} />
                      <Box className={classes.nav_active} />
                    </>
                  ) : (
                    <Box className={classes.nav_active} />
                  )}
                </Box>
              </NavLink>
            </Grid>
            <Grid
              item
              xs={1.2}
              md={1.5}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <NavLink to="projects" style={{ textDecoration: "none" }}>
                <Box className={classes.nav_links}>
                  <Typography className={classes.nav_link}>
                    &lt;Projects/&gt;
                  </Typography>

                  {location.pathname === "/projects" ? (
                    <>
                      <Box className={classes.nav_actives} />
                      <Box className={classes.nav_active} />
                    </>
                  ) : (
                    <Box className={classes.nav_active} />
                  )}
                </Box>
              </NavLink>
            </Grid>
            <Grid
              item
              xs={6}
              md={5.1}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <Box className={classes.nav_links}>
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                    position: "absolute",
                    top: "5%",
                    left: "70%",
                  }}
                >
                  <Hamburger
                    toggled={isOpen}
                    toggle={setOpen}
                    size={23}
                    onClick={() => setOpen(!isOpen)}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={1.3}
              md={1.5}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <NavLink to="contact" style={{ textDecoration: "none" }}>
                <Box className={classes.nav_links}>
                  <Typography className={classes.nav_link}>
                    &lt;Contact/&gt;
                  </Typography>

                  {location.pathname === "/contact" ? (
                    <>
                      <Box className={classes.nav_actives} />
                      <Box className={classes.nav_active} />
                    </>
                  ) : (
                    <Box className={classes.nav_active} />
                  )}
                </Box>
              </NavLink>
            </Grid>
          </Grid>
        </Container>
        {isOpen && width < 900 && (
          <Container disableGutters>
            <Grid container>
              <Grid item xs={12}>
                <NavLink to="/" style={{ textDecoration: "none" }}>
                  <Typography
                    onClick={() => setOpen(false)}
                    className={classes.nav_link_small}
                  >
                    &lt;Hello/&gt;
                  </Typography>
                </NavLink>
              </Grid>
              <Grid item xs={12}>
                <NavLink
                  to="/about/personal-information"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    onClick={() => setOpen(false)}
                    className={classes.nav_link_small}
                  >
                    &lt;About/&gt;
                  </Typography>
                </NavLink>
              </Grid>
              <Grid item xs={12}>
                <NavLink to="projects" style={{ textDecoration: "none" }}>
                  <Typography
                    onClick={() => setOpen(false)}
                    className={classes.nav_link_small}
                  >
                    &lt;Projects/&gt;
                  </Typography>
                </NavLink>
              </Grid>
              <Grid item xs={12}>
                <NavLink to="contact" style={{ textDecoration: "none" }}>
                  <Typography
                    onClick={() => setOpen(false)}
                    className={classes.nav_link_small}
                  >
                    &lt;Contact/&gt;
                  </Typography>
                </NavLink>
              </Grid>
            </Grid>
          </Container>
        )}
      </Container>
    </>
  );
};

export default Navbar;
